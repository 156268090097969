/*=============================
            Variables
  ===========================*/

/***** colors**********/

/*=========fonts ======*/
$color-base: #58666e;
$color-primary: #252939;
$color-secondary: #2f4cdd;
$color-light: #f0f3f4;
$color-white: #ffffff;
$color-muted: #b4b6bd;
$color-dark: #303030;
// hover colors
$color-primary-dark: #2e3345;
$color-secondary-light: rgba(52, 56, 205, 0.3);
$color-success: #98dd44;
$color-grey: #e3eaef;
$color-info: #4a74e9;
$color-danger: #f54747;

// fonts
$base-font-size: 14px;
$font-smallest: calc(1rem - 5px); //10px
$font-small: calc(1rem - 3px); //12px
$font-medium: calc(1rem + 1px); //16px
$font-heading-6: calc(1rem + 3px); // 18px
$font-heading-5: calc(1rem + 5px); // 20px
$font-heading-4: calc(2rem - 6px); // 24px
$font-heading-3: calc(2rem); // 30px
$font-heading-2: calc(2rem + 6px); // 36px
$font-heading-1: calc(1rem + 23px); // 48px
$font-large: calc(4rem); // 60px;
$font-largest: calc(5rem - 3px); // 72px;
/*=========fonts families======*/

$font-family-lato: "Lato", sans-serif;
$font-family-poppins: "Poppins", sans-serif;

/*=========Line Height======*/

$base-line-height: 24px;
$line-height-14: 14px;

/*=========letter spacing======*/

$letter-spacing-1: 1px;
$letter-spacing-2: 1px;
$letter-spacing-3: 1px;
