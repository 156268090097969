.settings-main {
 
  .img-upload-wrapper{
    text-align: center;
    .img-upload {
      max-width: 175px;
      height: 175px;
      width: 175px;
      object-fit: cover;
      margin: 0 auto;
      border-radius: 10px;
  
      img{
        max-width: 150px;
        height: 150px;
        width: 150px;
        object-fit: cover;
      }
    }
  }
  .rest-timing-box {
    .day-name {
      flex: 0 0 40px;
    }
    .availability {
      flex: 0 0 50px;
    }
  }
}
