html {
  scroll-behavior: smooth;
  font-size: $base-font-size;
}
body {
  font-family: $font-family-lato;
  color: $color-base;
  line-height: $base-line-height;
  background: $color-light;
}
*,
*:focus {
  outline: none !important;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
ul {
  list-style: none;
  padding-left: 0;
}
a {
  text-decoration: none !important;
  color: $color-secondary;
}
a:hover {
  color: $color-primary;
}
p {
  margin-bottom: 10px;
}
img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}
.app {
  min-height: 100vh;
}
/*****************************/
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  // font-family: $font-poppins;
  line-height: 1.2;
  font-weight: 600 !important;
}
h1,
.h1 {
  font-size: $font-heading-1; // 48px
}
h2,
.h2 {
  font-size: $font-heading-2; //30px
}
h3,
.h3 {
  font-size: $font-heading-3; // 26px
}
h4,
.h4 {
  font-size: $font-heading-4; // 20px
}
h5,
.h5 {
  font-size: $font-heading-5; //16 px
}
h6,
.h6 {
  font-size: $font-heading-6; // 14px
}
/******* Helpers ********************************************************************/
/*-----  fonts ---------- */
.font-inter {
  // font-family: $font-poppins !important;
}

.font-poppins{
  font-family: $font-family-poppins !important;
}

/* font sizes ======= */
.font-smallest {
  font-size: $font-smallest !important; //12px
}
.font-small {
  font-size: $font-small !important; // 13px
}
.font-large {
  font-size: $font-large !important;
}

.font-largest {
  font-size: $font-largest !important;
}

.text-primary,
a.text-primary:hover,
a.text-primary:focus {
  color: $color-primary !important;
}
.text-secondary,
a.text-secondary:hover,
a.text-secondary:focus {
  color: $color-secondary !important;
}

/* backgrounds ==== */
.bg-light {
  background-color: $color-light !important;
}
.bg-primary {
  background-color: $color-primary !important;
}
.bg-secondary {
  background-color: $color-secondary !important;
}

.bg-dark {
  background-color: $color-dark !important;
}
.bg-success {
  background-color: $color-success !important;
}
.bg-danger {
  background-color: $color-danger !important;
}
.bg-info {
  background-color: $color-info !important;
}
.bg-grey {
  background-color: $color-grey !important;
}

// bg transparent
.bg-transparent-dark {
  background-color: rgba($color-dark, 0.5) !important;
}
/*************Font Weight****************/
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-medium {
  font-weight: 500 !important;
}
.font-weight-semi {
  font-weight: 600 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: 800 !important;
}
// =============================
.container {
  max-width: 1470px;
}
.text-underline {
  text-decoration: underline !important;
}
//==========
.line-height-0 {
  line-height: 0;
}
.line-height-1 {
  line-height: 1;
}
.line-height-2 {
  line-height: 2;
}
//=========borders

// opacities ==============================
.opacity-one-fourth {
  opacity: 0.25;
}
.opacity-half {
  opacity: 0.5;
}
.opacity-three-fourth {
  opacity: 0.75;
}
.opacity-full {
  opacity: 1;
}
select.remove-default-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
//spacing classes

.mlr-10 {
  margin: 0px 10px;
}

// max width classes

.mw-25 {
  max-width: 25%;
}
.mw-50 {
  max-width: 50%;
}
.mw-75 {
  max-width: 75%;
}

//====== image-fit=====
.img-fit {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.cursor-pointer {
  cursor: pointer;
}

// border-colors=-===={
.border-dark {
  border-color: #000000 !important;
}

.border {
  &.border-primary {
    border-color: $color-primary !important;
  }
}

.text-deleted {
  text-decoration: line-through;
}

.rounded {
  border-radius: 10px !important;
}

.close {
  font-size: 20px;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px !important;
  margin-right: 0px !important;
  position: relative;
  z-index: 9;
}

.border-thick {
  border-width: 2px !important;
}

.no-arrow.dropdown-toggle:after {
  content: none;
}

.modal-content {
  border-radius: 0;
}
.custom-table-wrapper {
  .custom-table {
    margin-bottom: 0;
    th,
    td {
      padding: 15px 20px;
      border-bottom: 1px solid $color-light;
    }
    th {
      text-transform: uppercase;
      font-weight: 700;
      background-color: $color-grey;
      // color: $color-white;
    }
  }
}

.custom-tabs {
  &.nav-tabs {
    background-color: $color-white;
    margin: 0;
    .nav-link {
      background-color: $color-white;
      border-radius: 0;
      border: 0;
      margin: 0;
      color: $color-primary;
      font-size: $font-heading-5;
      padding: 15px 20px;
      font-weight: 700;
      border-bottom: 3px solid transparent;
      &.active {
        background-color: $color-grey;
        border-color: $color-secondary;
      }
    }
  }
}

.custom-tabs-type-2 {
  .nav-tabs {
    margin: 0;
    display: inline-flex;
    background-color: $color-grey;
    border: 0;
    padding: 5px;
    border-radius: 5px;
    .nav-item {
      flex-grow: auto;
      & + .nav-item {
        margin-left: 5px;
      }
      .nav-link {
        background-color: transparent;
        border-radius: 0;
        border: 0;
        margin: 0;
        color: $color-secondary;
        padding: 5px 10px;
        font-weight: 600;
        border-radius: 5px;
        &.active {
          background-color: $color-secondary;
          color: $color-white;
        }
      }
    }
  }
}

.img-upload-wrapper {
  text-align: center;
  .img-upload {
    max-width: 175px;
    height: 175px;
    width: 175px;
    object-fit: cover;
    margin: 0 auto;
    border-radius: 10px;

    img {
      max-width: 150px;
      height: 150px;
      width: 150px;
      object-fit: cover;
    }
  }
}

.modal-header {
  background-color: $color-grey;
  border-radius: 0;
}



/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $color-light;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-grey;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-secondary;

}
// =======================media querries=============================

@media (max-width: 767px) {
  html {
    font-size: 14px;
  }
  h1,
  .h1 {
    font-size: $font-heading-2; // 48px
  }
  h2,
  .h2 {
    font-size: $font-heading-3; //30px
  }
  h3,
  .h3 {
    font-size: $font-heading-4; // 26px
  }
  h4,
  .h4 {
    font-size: $font-heading-5; // 20px
  }
  h5,
  .h5 {
    font-size: $font-heading-6; //16 px
  }
  h6,
  .h6 {
    font-size: $font-smallest; // 14px
  }

  .slick-prev,
  .slick-next,
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    height: 40px;
    width: 40px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 40px;
    top: 3px;
    left: 0.5px;
  }
}
