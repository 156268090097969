.dashboard-main-wrapper {
  .basic-analytic-box {
    text-align: center;
    .img-box {
      margin: 0 auto;
      max-width: 100px;
      min-height: 125px;
    }
  }
  .top-selling-items {
   
    .top-selling-items-list{
      max-height: 500px;
      overflow-y: auto;
      .top-selling-item {
        padding: 10px;
        & + .top-selling-item {
          border-top: 1px solid $color-light;
        }
        display: flex;
        .ranking {
          font-size: $font-heading-4;
          margin-right: 10px;
        }
        .item-img {
          flex: 0 0 80px;
          max-width: 80px;
          margin-right: 10px;
          img {
            height: 80px;
            width: 80px;
            object-fit: cover;
            border-radius: 5px;
          }
        }
        .food-type {
          max-width: 20px;
        }
      }
    }
  }


  .top-performing-waiters  {
   
    .top-performing-waiters-list{
      max-height: 500px;
      overflow-y: auto;
      .top-performing-waiter {
        padding: 10px;
        & .top-performing-waiter {
          border-top: 1px solid $color-grey;
        }
        display: flex;
        .ranking {
          font-size: $font-heading-4;
          margin-right: 10px;
        }
        .item-img {
          flex: 0 0 75px;
          max-width: 75px;
          margin-right: 10px;
          img {
            height: 75px;
            width: 75px;
            object-fit: cover;
            border-radius: 50%;

          }
        }
        .food-type {
          max-width: 20px;
        }
      }
    }
  }
}
