@import "../abstract/variable";
.common-login {
  .left-content {
    background-color: $color-primary;
    color: $color-white;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .carousel {
      flex-direction: column;
      padding: 30px;
    display: flex;
     
      .carousel-inner {
        order: 1;
        .carousel-item {
          text-align: center;

          img {
            max-width: 320px;
          }
          .carousel-caption {
            position: static;
          }
        }
      }
      .carousel-indicators {
        position: static;
        order: 2;
      }
    }
  }

  .form-wrapper {
    width: 100%;
    position: relative;
    padding: 30px;
    min-height: 100vh;
    .form-inner {
      width: 100%;
      max-width: 450px;
      margin: 0 auto ;
      .rest-logo {
        img {
          max-width: 100px;
        }
      }
    }
  }
  .left-display-img {
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 25%;
  }
  .powered-by {
    position: absolute;
    top: 10px;
    width: 100%;
    max-width: 35%;
    left: 15px;
    img {
      max-width: 35px;
    }
  }
}
