@import "../../../assets/scss/abstract/variable";
.ant-picker-input {
    input {
        font-weight: bold;
        padding: 10px 20px;
        &::placeholder {
            color: $color-dark;
            font-size: $font-heading-6;
            font-weight: bold;
        }
    }
}

.ant-picker {
    margin: 0;
}

.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
    display: none;
}
.ant-picker-ranges{
  font-weight: bold;
}
.ant-picker-dropdown {
    .ant-picker-panel-container {
        border-radius: 5px;
    }
}

.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
    height: 10px;
}

.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
    border: 0 solid $color-primary;
    border-width: 2px 0 0 2px;
    height: 10px;
    width: 10px;
}

.ant-picker-header {
    button {
        font-weight: bold;
    }
}

.ant-picker-header-view {
    button {
        &:hover {
            color: $color-primary;
        }
    }
}

.ant-picker-cell-in-view.ant-picker-cell-selected {
    .ant-picker-cell-inner,
    .ant-picker-cell:hover {
        border-color: transparent;
        background: $color-light;
        color: $color-dark;
        border-radius: 5px;
    }
}

.ant-picker-cell-in-view.ant-picker-cell-today {
    .ant-picker-cell-inner {
        &::before {
            border-color: transparent;
        }
    }
}

.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) {
    .ant-picker-cell-inner {
        background: $color-light;
        border-radius: 5px;
    }
}