.food-list-items {
  .food-item {
    position: relative;
    overflow: hidden;
    .cover-img {
      max-height: 200px;
      img {
        height: 200px;
        object-fit: cover;
        object-position: center;
      }
      .btn-edit-category{
        position: absolute;
        bottom: 10px;
        right: -5px;
        // border-radius: 5px;
        padding-right: 15px !important;
      }
    }
    .content {
      .pricing-rating {
        .rating {
          .rating-box {
            height: 25px;
            width: 25px;
            line-height: 25px;
            text-align: center;
            border-radius: 5px;
            margin-right: 5px;
          }
        }
      }
    }
    .actions{
      visibility: hidden;
      opacity: 0;
  
    }
    &:hover{
      .actions{
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .discount-tag {
    position: absolute;
    top: 10px;
    left: -30px;
    z-index: 2;
    padding: 5px 35px;
    transform: rotate(-45deg);
    text-transform: uppercase;
  }
  .special-tag  {
    position: absolute;
    top: 00px;
    right: -2px;
    z-index: 2;
    padding: 5px 25px;
    
    text-transform: uppercase;
    font-size: $font-small;
  }
  
}
