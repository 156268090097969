// common inputs ======
.form-control,
.form-select {
  background-color: $color-white;
  border-color: $color-dark;
  color: $color-dark;
  font-weight: 600;
  font-size: $font-medium;
  border-radius: 0;
  padding: 10px;
  border-radius: 5px;
  background-color: $color-light;
  border: 0 !important;
  &:focus {
    outline: 0;
    box-shadow: none;
    background-color: $color-light;
  }
}
textarea.form-control:not([rows]) {
  height: 120px;
}

/* ================== placeholder color ==============  */

.form-control::-webkit-input-placeholder,
::-webkit-input-placeholder {
  /* Edge */
  color: $color-base;
  font-weight: 400;
}

.form-control:-ms-input-placeholder,
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $color-base;
  font-weight: 400;
}

.form-control::placeholder,
::placeholder {
  color: $color-base;
  font-weight: 400;
}

.form-control::-moz-placeholder {
  color: $color-base;
  font-weight: 400;

  opacity: 1;
}

// ===================== custom inputs ===============
// default violet
.form-check {
  .form-check-label {
    &:before {
      border-color: $color-primary;
    }
    &:focus ~ .cform-check-label::before {
      box-shadow: none !important;
    }
  }
  .form-check-input {
    &:focus ~ .form-check-label::before {
      box-shadow: none !important;
      border-color: $color-primary;
    }
    &:checked ~ .form-check-label::before {
      color: #fff;
      border-color: $color-primary;
      background-color: $color-primary;
    }
  }

  // checkbox (dark colored) ======
  &.dark {
    .custom-control-label {
      &:before {
        border-color: $color-dark;
      }
    }
    .custom-control-input {
      &:focus ~ .custom-control-label::before {
        border-color: $color-dark;
      }
      &:checked ~ .custom-control-label::before {
        border-color: $color-dark;
        background-color: $color-dark;
      }
    }
  }
  &.custom-radio {
    .custom-control-label {
      &:before {
        border-color: $color-muted;
        background-color: $color-muted;
      }
    }
    .custom-control-input {
      &:focus ~ .custom-control-label::before {
        border-color: $color-primary;
      }
      &:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: $color-primary;
        background-color: $color-primary;
      }
    }
  }
}

.form-switch {
  .form-check-input {
    width: 50px;
    height: 25px;
    margin-top: 0;
    margin-right: 10px;
    &:checked {
      background-color: $color-primary;
      border-color: $color-primary;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
    }
    &:checked:focus {
      background-color: $color-primary;
      border-color: $color-primary;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
    }
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: $color-primary;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
    }
  }
}
