.pg-add-food-item {
  .form-label {
    font-size: $font-medium;
    font-weight: 600;
  }
  .picture-upload {
    width: 100%;
    background-color: $color-primary;
    color: $color-white;
    padding: 10px;
    text-align: center;
    text-transform: capitalize;
  
    cursor: pointer;
    .icon {
      font-size:  100px ;
      margin-bottom: 20px;
    }
  }
}
