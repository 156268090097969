@import "../../../assets/scss/abstract/variable";

.staff-listing-main {
  .staff-list {
    .staff-item {
      position: relative;
      overflow: hidden;
      .rating{
        position: absolute;
        top: 7px;
        left: -27px;
        padding: 5px 20px;
        min-width: 100px;
        transform: rotate(-45deg);
      }
      h5,
      p,
      ul,
      li {
        transition: all 0ms;
      }
      .staff-img {
        max-height: 125px;
        width: 125px;
        margin: 0 auto;
        border-radius: 50%;
        overflow: hidden;
        line-height: 1;

        border: 3px solid $color-primary;

        img {
          height: 125px;
          object-fit: cover;
          object-position: center;
        }
        &:hover {
          img {
            transform: scale(1.2);
          }
        }
      }

      &:hover {
        transform: scale(1.03);
      }
    }
  }
}
.view-staff-modal {
  .modal-content {
    border-radius: 10px;
    overflow: hidden;
    &::before {
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      max-width: 50%;
      position: absolute;
      max-width: calc(33.333333%);
      // background-color: rgba(0, 11, 73, 0.9) ;
      background-color: $color-secondary;
      height: 100%;
    }
  }
  .staff-brief-wrapper {
    border-radius: 20px;
    .staff-brief {
      .staff-img {
        background-color: $color-white;
        padding: 10px;
        border-radius: 15px;
        img {
          width: 100%;
          max-width: 200px;
        }
      }
    }
  }
}
.review-modal {
  .review {
    .user {
      flex: 0 0 42px;
      height: 42px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
