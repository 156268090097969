.admin-home-dashboard {
    .main-content {
        .nav-tabs {
            border: none;
            padding: 15px 0;
            position: fixed;
            top: 0;
            width: 1470px;
            max-width: 100%;
            background-color: $color-white;
            left: 50%;
            transform: translateX(-50%);
            padding-left: 110px;
            z-index: 9;
            .nav-link {
                font-size: $font-heading-5;
                // font-family: $font-poppins;
                font-weight: 700;
                color: $color-dark;
                border: 1px solid transparent;
                border-radius: 4px;
                margin-right: 2px;
                &.active,
                &:hover {
                    border: 1px solid #24AAE1;
                }
            }
        }
        .tab-pane {
            padding-top: 80px;
            .subcontent{
                display: none;
                &.active{
                    display: block;
                }
            }
        }
    }
}