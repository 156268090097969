.table-dashboard-main {
  .menu-managment-box {
    transition: all 300ms ease-in;
    .img {
      max-width: 125px;
      margin: 0 auto 20px;
    }
    &:hover{
      transform:scale(1.05);
    }
  }
}
