/********* Buttons ******************/
.btn {
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  text-decoration: none !important;
  font-weight: 500;
  font-size: $font-medium;
  padding: 10px 20px;
  border-radius: 0;
  &:focus {
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  &.btn-sm {
    padding: 7px 15px;
    font-size: $font-small;
  }

  // button primary (violet background)
  &.btn-primary {
    background-color: $color-primary !important;
    border-color: $color-primary !important;
    color: $color-white !important;
    &:focus,
    &:hover {
      background-color: rgba($color-primary, 0.8) !important;
      border-color: rgba($color-primary, 0.8) !important;
      color: $color-white !important;
    }
  }

  // button primary (violet background)
  &.btn-secondary {
    background-color: $color-secondary !important;
    border-color: $color-secondary !important;
    color: $color-white !important;
    &:focus,
    &:hover {
      background-color: rgba($color-secondary, 0.8) !important;
      border-color: rgba($color-secondary, 0.8) !important;
      color: $color-white !important;
    }
  }

  &.btn-success {
    background-color: $color-success !important;
    border-color: $color-success !important;
    color: $color-white !important;
    &:focus,
    &:hover {
      background-color: rgba($color-success, 0.8) !important;
      border-color: rgba($color-success, 0.8) !important;
      color: $color-white !important;
    }
  }

  &.btn-info {
    background-color: $color-info !important;
    border-color: $color-info !important;
    color: $color-white !important;
    &:focus,
    &:hover {
      background-color: rgba($color-info, 0.8) !important;
      border-color: rgba($color-info, 0.8) !important;
      color: $color-white !important;
    }
  }
  &.btn-danger {
    background-color: $color-danger !important;
    border-color: $color-danger !important;
    color: $color-white !important;
    &:focus,
    &:hover {
      background-color: rgba($color-danger, 0.8) !important;
      border-color: rgba($color-danger, 0.8) !important;
      color: $color-white !important;
    }
  }
  &.btn-outline-white {
    background-color: transparent !important;
    border-color: $color-white !important;
    color: $color-white !important;
    &:focus,
    &:hover {
      background-color: $color-white !important;
      border-color: $color-white !important;
      color: $color-primary !important;
    }
  }
  &.btn-outline-primary{
    background-color: transparent !important;
    border-color: $color-primary !important;
    color: $color-primary !important;
    &:focus,
    &:hover {
      background-color: $color-primary !important;
      border-color: $color-primary !important;
      color: $color-white !important;
    }
  }
}
