.pg-category-list {
  .food-category-list {
    .food-category {
      position: relative;
      overflow: hidden;
      .tag {
        position: absolute;
        top: 10px;
        left: -30px;
        z-index: 2;
        padding: 5px 35px;
        transform: rotate(-45deg);
        text-transform: uppercase;
      }
      .cover-img{
        min-height: 150px;
        line-height: 0;
        img{
          height: 150px;
          object-fit: cover;
          object-position: center;
        }
      }
      .performance-rating{
        .rating{
          .rating-box {
           height: 20px;
           width: 20px;
           border-radius: 5px;
           line-height: 20px;
           text-align: center;
           margin-right: 8px;
          }
        }
      }
      .category-name{
        opacity: 1;
      }

     
 
    }

    
   
  }
}
