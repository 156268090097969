.download-qr-main {
    
  .qr-box-wrapper {
    // padding: 15px;
    max-width: 320px;
    margin: 0 auto;
    background-color: $color-white;
    .qr-box {
    
      .top {
                padding: 10px;
        .restaurant-logo {
          max-width: 120px;
        }
        h1{
          line-height: 1.12;
        }
      }
      .middle {
        padding: 30px;
      }
      .qr-code {
        margin-bottom: 30px;
        img {
          max-width: 200px;
        }
      }
      .bottom {
        border-top: 1px solid rgba($color-primary, 0.3);
        padding: 10px;
        .fundine-logo{
          img{
            max-width: 20px;
          }
        }
      }
    }
  }
}
