.admin-dashboard-section {
  padding-bottom: 40px;
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 250px;
    height: 100vh;

    .resturant-logo {
      padding: 20px 10px;
      margin-bottom: 10px;
      color: $color-white;
      font-style: italic;
      border-bottom: 1px solid rgba($color-white, 0.2);
      display: flex;
      align-items: center;
      .logo {
        max-width: 40px;
        margin-right: 15px;
      }
      font-size: $font-medium;
      font-weight: 600;
    }
    .side-menu {
      margin-top: 0px;
      max-height: calc(100vh - 73px);
      overflow-y: auto;
      padding-bottom: 25px;
      ul {
        li {
          button {
            width: 100%;
            background-color: transparent;
            border: 0;
            text-align: left;
            padding: 10px 15px;
            color: rgba($color-white, 0.6);
            border-left: 5px solid transparent;
            margin-bottom: 2px;
            display: flex;
            align-items: flex-start;
            .icon {
              flex: 0 0 25px;
            }
            &:hover,
            &.active {
              color: $color-white;
              border-left: 5px solid $color-secondary;
              background-color: $color-primary-dark;
            }
          }
        }
      }
      // .accordion {
      //   --bs-accordion-bg: transparent;
      //   .accordion-item {
      //     margin: 6px 12px;
      //     border: 0;
      //     .accordion-header {
      //       font-size: calc(2rem);
      //       .accordion-button {
      //         background: transparent;
      //         // color: rgba($color-white, 0.6);
      //         box-shadow: none;
      //         border-radius: 0;
      //         font-weight: 400;
      //         padding: 10px 5px;
      //         &:after {
      //           content: none;
      //           background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      //         }
      //         &:not(.collapsed),
      //         &:hover {
      //           color: $color-white;
      //           background-color: $color-primary;
      //         }
      //         &:not(.collapsed):after,
      //         &:hover:after {
      //           background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      //         }
      //         .icon {
      //           font-size: 20px;
      //           margin-right: 10px;
      //           min-width: 30px;
      //         }
      //       }
      //     }
      //     .accordion-body {
      //       padding: 0;
      //       margin: 5px 0;
      //       ul {
      //         border-radius: 5px;
      //         padding: 10px 20px;

      //         li {
      //           margin: 8px 5px;
      //           a {
      //             // color: rgba($color-white, 0.6);
      //             color: $color-base;
      //             display: flex;
      //             align-items: center;
      //             padding: 5px;
      //             font-weight: 400;
      //             border-radius: 5px;
      //             .icon {
      //               font-size: 16px;
      //               margin-right: 10px;
      //             }
      //             &.active,
      //             &:hover {
      //               // color: $color-white;
      //               color: $color-primary;
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }

  header {
    position: fixed;
    top: 0;
    left: 250px;
    width: 100%;
    max-width: calc(100% - 250px);
    padding: 10px 15px;
    z-index: 999;
    // box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.16);

    .btn-menu-toggle {
      font-size: $font-heading-5;
    }

    .header-actions {
      .btn-indicator {
        // position: relative;
        // padding: 5px;
        // font-size: $font-heading-4;
        // color: $color-primary;
        // margin-left: 10px;
        position: relative;
        padding: 5px;
        font-size: $font-heading-4;
        color: $color-muted;
        margin-left: 10px;
        &:focus {
          outline: 0;
          box-shadow: none;
        }
        .count-indicator {
          font-size: $font-smallest;
          font-weight: 500;
          height: 20px;
          width: 20px;
          display: inline-block;
          border-radius: 50%;
          text-align: center;
          line-height: 20px;
          position: absolute;
          top: -3px;
          right: -4px;
        }
      }

      .dropdown-menu {
        min-width: 320px;
        border: 0;
        box-shadow: 0 0 5px rgba($color-dark, 0.2);
        padding: 0;
        border-radius: 0;
      }
      .header-messages {
        .messages-list {
          max-height: 400px;
          overflow-y: auto;
          .message-box {
            padding: 10px;
            cursor: pointer;
            .user-img {
              max-width: 50px;
              margin-right: 10px;
              img {
                height: 50px;
                width: 50px;
                border-radius: 50%;
                object-fit: cover;
                object-position: center;
              }
            }
            p {
              margin-bottom: 0;
            }
            & + .message-box {
              border-top: 1px solid rgba($color-dark, 0.2);
            }
          }
        }
      }

      .header-notifications {
        .notifications-list {
          max-height: 400px;
          overflow-y: auto;
          .notification-box {
            padding: 10px;
            cursor: pointer;
            .icon {
              max-width: 50px;
              margin-right: 10px;
              background-color: rgba($color-secondary, 0.5);
              border-radius: 50%;
              height: 50px;
              padding: 10px;
              img {
                max-width: 100%;
              }
            }
            p {
              margin-bottom: 0;
              line-height: 1.4;
            }
            & + .notification-box {
              border-top: 1px solid rgba($color-dark, 0.2);
            }
          }
        }
      }

      .header-user {
        margin-left: 10px;
        .btn {
          padding: 0;
        }
        .user-img {
          max-width: 30px;
          height: 30px;
          img {
            // height: 40px;
            // width: 40px;
            // object-fit: cover;
            // object-position: center;
            // box-shadow: 0 0 20px 0 rgba($color-dark, 0.2);
            // border: 1px solid rgba($color-primary, 0.8);
            // border-radius: 50%;
            height: 30px;
            width: 30px;
            object-fit: cover;
            object-position: center;
            box-shadow: 0 0 20px 0 rgba($color-dark, 0.2);
            // border: 1px solid rgba($color-primary, 0.8);
            border-radius: 50%;
          }
        }
        .dropdown-menu {
          min-width: 225px;
          border: 0;
          box-shadow: 0 0 10px rgba($color-primary, 0.5);
          border-radius: 0;
          background-color: rgba($color-primary, 0.2);
          background-color: $color-primary;
          color: $color-white;
          padding: 10px;
          a {
            color: rgba($color-white, 0.6);
            display: block;
            padding: 5px 10px;
            border-radius: 10px;

            &:hover {
              background: rgba($color-white, 1);
              color: $color-primary;
            }
            .icon {
              min-width: 30px;
              display: inline-block;
              font-size: $font-heading-6;
            }
          }
          h5 {
            .rest-icon {
              max-width: 40px;
              margin-right: 10px;
              font-size: $font-medium;
            }
            border-bottom: 1px solid rgba($color-secondary, 0.5);
            padding: 10px 5px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .dashboard-main-content {
    margin-top: 100px;
    padding-left: 260px;
  }
}
